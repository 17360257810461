header {
	h1 {
		font-family: $stack-serif;
		text-align: center;
		font-size: 3rem;
		margin-bottom: 0;
		a {
			color: $charcoal;
		}
	}
}