@import 'normalize';
@import 'vars';
@import 'fonts';
// @import 'mixins';
// @import 'animations';

@import 'base';
@import 'extenders';
@import 'home';
@import 'footer';
@import 'header';
// @import 'services';
@import 'nav';
@import 'article';
@import 'images'; 
// @import 'codesyntax';
// @import 'forms';
// @import 'buttons';
// @import 'thanks';
// @import 'sideNav';
// @import 'projectpane';
@import 'lists';
@import 'tables';
@import 'newsletter';
@import 'mq800px';
@import 'error'; 

@import 'font-awesome';

// @import 'media-queries'; 
