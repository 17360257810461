@import "fa/fa-variables";
@import "fa/fa-mixins";
@import "fa/path";
@import "fa/core";
@import "fa/larger";
@import "fa/fixed-width";
@import "fa/list";
@import "fa/bordered-pulled";
@import "fa/animated";
@import "fa/rotated-flipped";
@import "fa/stacked";
@import "fa/icons";
@import "fa/screen-reader";