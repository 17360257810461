main {
  display: grid;
  grid-template-columns: 1fr 40rem 1fr;
}

section.content {
	// padding: 0 1.5rem;
	// display: flex;
	height: min-content;
	grid-row-start: 1;
	grid-column-start: 2;
	// justify-content: space-evenly;
	// grid-row-start: 1;
	// grid-column-start: -2;
	// margin:  2rem;
	// @extend %shadow;	
	div > h2 {
		font-family: $stack-serif;
		margin-top: 0;
	}  
}

section.blog-roll-middle {
	grid-column-start: 2;
	grid-row-start: 2;
}

.self-image {
	float: left;
	margin-right: 1.5rem;
	margin-bottom: 4.5rem;
	max-width: 25%;
	// border-radius: 50%;

	padding: 2px;
	border: 1px solid $charcoal;
	object-fit: cover;
}

.quill-icon {
	width: 5%;
	margin: 3rem auto 0;
	&+ header > h2 {
		margin-top: 0;
	}
}

hr.custom {
	margin-top: 3rem;
	border-top: 1px solid #8c8b8b;
	text-align: center;
}
hr.custom:after {
	content: '§';
	display: inline-block;
	position: relative;
	top: -14px;
	padding: 0 10px;
	background: $offwhite;
	color: #8c8b8b;
	font-size: 18px;
}

.about.self-image {
	margin-bottom: 1rem;
}