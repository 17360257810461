table { 
	text-align: center;
	width: 100%;
	margin-bottom: 1rem;
	&.fixed {
		table-layout: fixed;
	}
 }


caption, td, th {
 	border: $gray $standard-border;
 	padding: 0.25rem;
 }

 th, caption {
 	background-color: $lightmaincolor;
 }

 caption {
 	border-bottom: none;
 	font-weight: bold;
 }

 tbody:nth-child(even) {
 	background-color: $lightgray;
 }