%shadow {
  box-shadow: 4px 4px 0 3px $border1, 4px 4px $background,
              8px 8px 0 3px $border2, 8px 8px  $background,
              12px 12px 0 3px $border3, 12px 12px $background;
  transition: box-shadow 1s, bottom 1s, right 1s;
  bottom: 0;
  right: 0;
  ///border: 3px solid $border0;
  &:hover {
    top: -40px;
    left: 40px;
    box-shadow: 0 0 0 -3px $background, 0 0 0 0 $border0,
      0 0 0 -3px $background, 0 0 0 0  $border0,
      0 0 0 -3px $background, 0 0 0 0  $border0,
      0 0 0 -3px  $background, 0 0 0 0  $border0;
    border-bottom: 3px solid $border2;
  }
}