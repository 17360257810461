figure.splash {
	margin: 0 0 2.5rem 0;
	background-color: $offwhite;
	width: 100%;
	// display: flex;
    // justify-content: center; /* align horizontal */
    // align-items: center;
    //flex-wrap: wrap;
    figcaption {
		font-style: italic;
		font-size: 0.9rem;
		margin: 0;
		color: $gray;
		text-align: right;
		border-bottom: #94949b 1px dotted;
		//align-self: flex-end;
	}
  //   img {
		 //align-self: center;
  //   }
}

figure.left {
	margin: 0 1rem 0 0;
	float: left;
}

figure.right {
	margin: 0 0 0 1rem;
	float: right;
}

figure.center {
	margin: 0 auto;	
}