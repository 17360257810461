body {
	margin: 0;
	color: $charcoal;
	font-family: $stack-serif;
	background: $offwhite;
 }

a {
	text-decoration: none;
	color: $link;
	&:-webkit-any-link {
		cursor: pointer !important;
	}
	&:hover {
	    color: $hover;
	    cursor: pointer;
	}
	&:active {
		color: darken($hover, 15%);
	}
}

h1, h2, h3, h4, h5 {
	margin-bottom: 0.5em;
	padding-bottom: 0;
	text-shadow: $shadow;
	font-family: $stack-ss;
}
p {
	line-height: 1.75;
	padding-bottom: 1em;
	font-size: 1.25rem;
	// widows: 2;
	// orphans: 2;
	hyphens: 2;
	margin: 0;
	&.center {
		text-align: center;
	}
}
li, li ul li {
	font-family: $stack-serif;
	font-size: 1.25rem;
	widows: 2;
	orphans: 2;	
	line-height: 1.75;
}
img {
	max-width: 100%;
}

summary {
	font-weight: bold;
	font-size: 1.25rem;
}
details {
	margin-bottom: 1rem;
	margin-left: 1rem;
	border: red dotted 1px;
	padding: 0.5rem;
}

blockquote {
	//background-color: $lightcomplementcolor;
	border-left: $lightcomplementcolor solid 1rem;
	margin: 0 0 1rem 0;
	padding:  2rem 2rem 1rem 2rem;
	font-size: 90%;
}

i.gray {
	color: $gray;
}