footer, section.archive-link {
	color: $gray;
	margin-top: 2rem;
	font-weight: normal;
	text-align: center;
  p.center {
    font-size: 1rem;
  }
}
#breadcrumbs {
	font-size: 0.85rem;
	
}

// horizontal rule

hr.jagged {
  display:block;
  margin:4em auto;
  width:$hrSize;
  height:$hrSize;
  border-width:$borderWidth $borderWidth 0 0;
  border-color:#555;
  border-style:solid;
  position:relative;
  transform:rotate(-45deg);
  &:before,
  &:after{
    content:'';
    display:block;
    position:absolute;
    border:inherit;
    width:100%;
    height:100%;
    top: $borderWidth;
    
  }
  &:before{
    right:100%;
    top:-100%;
    margin-top:-($borderWidth);
  }
  &:after{
    left:100%;
    top:100%;
  }
}

.copyright {
        font-size: 0.85rem;
    }
    // .social {
    //   a {
    //     font-style: italic;
    //   }
    // }