section.topnav {
  // display: flex;
  // justify-content: center;
  // flex-wrap: wrap;
  box-shadow: 0px 19px 24px -25px $gray;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
}

nav {
  font-weight: bold;
  // width: 686px;
  margin: 0 auto;
  // margin-left: 2rem;
  > ul {
   list-style-type: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   // margin: 3rem 0 0 0;
   // margin-top: 3rem;
   margin-top: 0;
   padding: 0;
    > li {
    font-family: $stack-ss;
    text-align: center;
    margin: 0 1rem 0;
    font-size: .9rem;
    // @extend %shadow;
    > a {
      display: block;
      width:  80px;
      height: 1rem;
      padding: 0.5rem;
      border-bottom: 2px solid $maincolor;
      transition: border-bottom .4s ease,
                  box-shadow .4s ease, 
                  transform .4s ease;
      &:hover {
        // background-color: $maincolor;
        // color: $offwhite;
        border-bottom: 2px solid $border2;
        box-shadow: 8px 9px 0 -3px $border3;
        transform: translate(-4px, -4px);
      }
    }
   }
  }
}
ul.pageNav {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: space-between;
}