/* textures */
$paper: url(../img/back.jpg);

/* black and white */
$charcoal: #2f3036;
$offwhite: #f8f8ff;
$gray: mix($charcoal, $offwhite);

/* colors */

$maincolor: #AA5B39;

$complementcolor: complement($maincolor);
$bordercolor: darken($complementcolor, 20%);
$lightmaincolor: lighten($maincolor, 50%);
$lightcomplementcolor: lighten($complementcolor, 50%);
$lightgray: lighten($gray, 30%);

/* fonts */
$stack-ss: 'Raleway', Helvetica, sans-serif;
$stack-serif: 'Vollkorn', Athelas, Georgia, serif;
$stack-logo: 'Abril Fatface', $stack-ss;
$stack-mono: 'Source Code Pro', Menlo, Consolas, Monaco, monospace;

/* borders */
$standard-border: solid 2px;

/* shadows */
$shadow: 0 1px 0 $gray;

$border0: $maincolor;
$border1: lighten($border0, 10%);
$border2: lighten($border0, 20%);
$border3: lighten($border0, 30%);
$link: $complementcolor;
$hover: darken($link, 20%);
$background: lighten($border0, 50%);

$borderWidth:1px;
$hrSize:12px;