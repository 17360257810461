main p.meta, main p.byline {
  font-weight: bold;
  font-size: 1rem;
}

main header.postheader {
  border-bottom: $gray 1px dotted;
  margin-bottom: 1rem;
}

article {
  grid-row-start: 1;
  grid-column-start: 2;  
	// padding: 1.5rem;
	// width: 500px;
	// border: $maincolor 2px solid;
	margin-bottom: 1.5rem;
	// &:only-child {
	
		// padding: 1rem;
	margin: 0 0 1.5rem;
	// }
	// p.meta + p {
	// 	border-top: $lightmaincolor $standard-border;
	// 	border-bottom: $lightmaincolor $standard-border;
	// 	padding-top: 1rem;
	// }
}

div.blog-roll {
	display: flex;
	justify-content: left safe;
	flex-wrap: wrap;
	width: 800px;
	margin: 0 auto;
}
a.card {
	transition: 1s ease;
	.post {
		.hover-preview, .no-preview {
			width: 200px;
			height: 250px;
			padding: 1rem;
			margin: 1.2rem .75rem 0 .75rem;
			border: $maincolor 2px dashed;
			// @extend %shadow;
      p {
        font-size: 1rem;
        line-height: 1;
      }
		}
		.hover-preview {
			display: none;
		}
		.no-preview {
			// text-align: right;
			// h3 {
			// 	margin-top: 8rem;
			// }
		}
		h3 {
			font-size: 1rem;
		}
	}
	&:hover {
		// transform: translate(-1px, -1px);
		transition: all 2s ease;
		.post {
			.hover-preview {
				display: block;
				background-color: $maincolor;
				color: $offwhite;
			}
			.no-preview {
				display: none;
			}
		}
	}
}

p.permalink {
	padding-top: 1rem;
	border-top: dashed 1px $maincolor;
	font-size: 1rem;
    // a {
    //   display: block;
    //   width: 80px;
    //   height: 1rem;
    //   padding: 0.5rem;
    //   border-bottom: 2px solid $maincolor;
    //   transition: border-bottom .4s ease,
    //               box-shadow .4s ease, 
    //               transform .4s ease;
    //   &:hover {
    //     // background-color: $maincolor;
    //     // color: $offwhite;
    //     border-bottom: 2px solid $border2;
    //     box-shadow: 8px 9px 0 -3px $border3;
    //     transform: translate(-4px, -4px);
    //   }
    // }
}

article section {
  margin: 0;
}