@media all and (max-width: 800px) {
  figure.left, figure.right {
    float: none;
    margin: 0 auto 1rem;
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center; /* align horizontal */
    align-items: center;
  }
  .self-image {
    float: none;
    margin-bottom: 1rem;
  }
  main {
    display: block;
    nav > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: center;
      padding-left: 0;
      li {
        box-shadow: none;
        margin: 1px;
      }
    }
    section, article {
      margin: 0.5rem 2rem;
      width: auto;
    }

    article section {
      margin: 0;
    }
  }
  div.blog-roll {
    width: auto;
    display: block;
  }
  a.card {
    .post {
      .hover-preview {
        display: block;
        width: auto;
        height: auto;
        margin: 0.5rem 2rem;
        box-shadow: none;
      }
      .no-preview {
        display: none;
      }
    }
  }
  header {
    > h1 {
      font-size: 2rem;
    }
  }
  #mc_embed_signup {
    width: 100%;
  }
}